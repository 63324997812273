import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    personalizedproducts: `${apiPath}/personalizedproducts`,
    studentsByClassroomAndVerificationStatus :`${apiPath}/students/by-classroom-list/verification-status`,
    studentOrdersByProductTypeAndOrderStatus:`${apiPath}/personalizedproducts/orders/student/by-product-type-order-status`,
    placeOrder:`${apiPath}/personalizedproducts/orders/place-order`,
    ordersByProductTypeAcademicYear:`${apiPath}/personalizedproducts/orders`,
    verifyStudents:`${apiPath}/students/verify`,
    ordersByStatus:`${apiPath}/personalizedproducts/orders/student/by-product-type-order-status`,
    byOrderId:`${apiPath}/personalizedproducts/orders/student`,
    byOrderIdEmployee:`${apiPath}/personalizedproducts/orders/employee`,
    productDesigns:`${apiPath}/personalizedproducts/designs`,
    defaultPersonalizedProductDesignsByProductType:`${apiPath}/personalizedproducts/designs/default`,
    personalizedProductDesigns:`${apiPath}/personalizedproducts/designs/`,
    createPersonalizedProductDesignsFromDefaultSetup:`${apiPath}/personalizedproducts/designs/create-from-default-setup`,
    schoolOrders: `${apiPath}/personalizedproducts/orders/all`,
    printDocument: `${apiPath}/personalizedproducts/orders/print`,
    orderStatus: `${apiPath}/personalizedproducts/orders/status`,
    studentOrder: `${apiPath}/personalizedproducts/orders/student`,
    reOrder: `${apiPath}/personalizedproducts/orders/re-order`,
    studentDetails: `${apiPath}/students/profile-and-contact`,
    defaultDesignSetup: `${apiPath}/personalizedproducts/designs/default`,
    defaultTemplateSetup: `${apiPath}/personalizedproducts/templates/default`,
    employeeByDepartmentAndVerificationStatus :`${apiPath}/hr/employees/byDept/verification-status`,
    verifyEmployees:`${apiPath}/hr/employees/verify`,
    employeeOrdersByProductTypeAndOrderStatus:`${apiPath}/personalizedproducts/orders/employee/by-product-type-order-status`,
    departments: `${apiPath}/hr/employees/departments`,
    employeeDetails: `${apiPath}/hr/employees`
};


export const PublicAPI = [
    APIConstant.byOrderId,
    APIConstant.byOrderIdEmployee,
    APIConstant.printDocument,
    APIConstant.reOrder,
    APIConstant.studentOrder,
    APIConstant.orderStatus
];
