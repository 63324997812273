import { Component, OnInit } from "@angular/core";
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Router } from "@angular/router";
import { PersonalizedProductsService } from "../core";

@Component({
    template: "",
})
export class ValidateComponent implements OnInit {
    private images: any[] =[];

    constructor(private authService: UserAuthService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService,
        private productService: PersonalizedProductsService) { }

    ngOnInit() {
        this.getToken();
        this.getSecurityGroups();
        this.getAllStudentImages();
        this.getAllEmployeeImages();
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            this.router.navigateByUrl("/", {
                preserveQueryParams: false,
            });
            // const code = urlParams.get("code");
            // await this.authService.saveToken(token);
            // this.authService.saveOrgCode(code);
            // this.schoolService.getSchoolProfileType().subscribe((response) => {
            //     this.router.navigateByUrl(response.type === Type.school ? "/class-section" : "/degree-department", {
            //         preserveQueryParams: false,
            //     });
            // });
            // this.getSecurityGroups();
            // this.getColumnConfig();
            // setTimeout(() => {
            //     this.router.navigateByUrl('/dashboard', { preserveQueryParams: false });
            // }, 0);
        }
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('PersonalizedProducts');
      }

      private getAllStudentImages() {
        this.productService.getAllStudentImages().subscribe((response) => {
           this.images = response;
              sessionStorage.setItem('studentImages',JSON.stringify(this.images));
    
        });
      }

      private getAllEmployeeImages() {
        this.productService.getAllEmployeeImages().subscribe((response) => {
           this.images = response;
           console.log("images "+JSON.stringify(this.images))
              sessionStorage.setItem('employeeImages',JSON.stringify(this.images));
    
        });
      }
}
