import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CRUDService, BaseService, CommonUtility, CommonConstant } from "projects/common/src/public-api";
import { APIConstant, PublicAPI } from "../constant";
import { Classroom, Course, Degree, Department, School } from "models";
import { catchError, mergeMap } from "rxjs/operators";
import { Images } from "@attendance-core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class PersonalizedProductsService extends CRUDService<any> {
    constructor(protected baseService: BaseService,
        private http: HttpClient) {
        super(baseService, APIConstant.personalizedproducts + "/");
    }

    getDepartments() {
        return this.baseService.get<any>(`${APIConstant.departments}`);
    }

    getEmployeeByDepartmentListAndVerificationStatus(departments, isVerified): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.employeeByDepartmentAndVerificationStatus}` + "?isVerified=" + isVerified, departments);
    }

    verifyEmployees(employeeProfileIds) {
        return this.baseService.put(`${APIConstant.verifyEmployees}`, employeeProfileIds);
    }

    getEmployeeOrdersByProductTypeAndOrderStatus(departments, productType, orderStatus): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.employeeOrdersByProductTypeAndOrderStatus}` + "?productType=" + productType + "&orderStatus=" + orderStatus, departments);
    }

    getStudentsByClassroomListAndVerificationStatus(classrooms, isVerified): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.studentsByClassroomAndVerificationStatus}` + "?isVerified=" + isVerified + "&includeContactDetails=true", classrooms);
    }

    getStudentOrdersByProductTypeAndOrderStatus(classrooms, productType, orderStatus): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.studentOrdersByProductTypeAndOrderStatus}` + "?productType=" + productType + "&orderStatus=" + orderStatus, classrooms);
    }

    placeOrder(studentProfileIdList, productType, profileType) {
        return this.baseService.post<any>(`${APIConstant.placeOrder}` + "?profileType=" + profileType + "&productType=" + productType, studentProfileIdList);
    }

    getOrdersByProductTypeAcademicYear(productType, academicYear, profileType) {
        return this.baseService.get(`${APIConstant.ordersByProductTypeAcademicYear}` + "?profileType="+ profileType +"&productType=" + productType + "&academicYear=" + academicYear);
    }

    verifyStudents(studentProfileIds) {
        return this.baseService.put(`${APIConstant.verifyStudents}`, studentProfileIds);
    }

    getAllStudentImages(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.basePath}/documents/allFilesInFolder?module=Students`);
    }

    getAllEmployeeImages(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.basePath}/documents/allFilesInFolder?module=Employees`);
    }


    getStudentOrdersByOrderId(orderId) {
        return this.baseService.get(`${APIConstant.byOrderId}` + "?orderId=" + orderId);
    }

    getPersonalizedProductDesignsBySchool(profileType) {
        return this.baseService.get(`${APIConstant.productDesigns}?profileType=${profileType}&includeUrl=true`);
    }

    createPersonalizedProductDesignsFromDefaultSetup(profileType) {
        return this.baseService.post(`${APIConstant.createPersonalizedProductDesignsFromDefaultSetup}?profileType=${profileType}`, {});
    }

    getAllPendingSchoolsOrdersByOrderStatusList(orderStatus, profileType) {
        return this.baseService.get(`${APIConstant.schoolOrders}?profileType=${profileType}&orderStatus=${orderStatus}`);
    }

    getAllSchoolsOrdersByOrderStatusList(status1, status2, profileType) {
        return this.baseService.get(`${APIConstant.schoolOrders}?profileType=${profileType}&orderStatus=${status1}&orderStatus=${status2}`);
    }

    getStationaryStudentOrdersByOrderId(orderId, selectedSchool) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = selectedSchool;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.get(`${PublicAPI[0]}` + "?orderId=" + orderId, { headers: headers });
    }

    getStationaryEmployeeOrdersByOrderId(orderId, selectedSchool) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = selectedSchool;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.get(`${PublicAPI[1]}` + "?orderId=" + orderId, { headers: headers });
    }

    getDocument(data: any): Observable<any> {
       console.log("data "+JSON.stringify(data))
       let headers = {};
       headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
       headers['SCHOOL_CODE'] = data?.selectedSchool;
       headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
       headers['Accept'] = '*/*';
       if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
           headers['APP_NAME'] = 'SmartParent';
           headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
       } else {
           headers['APP_NAME'] = 'Spectrum';
       }
        console.log('header '+JSON.stringify(headers));
        return this.http.get(`${APIConstant.basePath}/documents/get-sysadmin?module=` + data.module + '&fileName=' + data.fileName+'&schoolCode='+data?.selectedSchool, { headers: headers });

    }


    printDocument(orderStatus, orderId, selectedSchool, profileType) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = "SYSTEM_ADMIN";
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.get(`${PublicAPI[2]}?productType=${orderStatus}&orderId=${orderId}&profileType=${profileType}&selectedSchool=${selectedSchool}`, { headers: headers });
    }

    updateOrderStatus(selectedSchool, orderId, profileType) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = selectedSchool;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.put(`${APIConstant.orderStatus}?orderId=${orderId}&profileType=${profileType}&orderStatus=Processing`, null, { headers: headers });
    }

    updateStudentOrder(params: any, selectedSchool) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = selectedSchool;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.put(`${APIConstant.studentOrder}`, params, { headers: headers });
    }

    reOrder(orderId: any, productType: any, params: any, selectedSchool: any, profileType: any) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = selectedSchool;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
            headers['STUDENT_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.studentProfileId)}`;
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.post(`${APIConstant.reOrder}?orderId=${orderId}&productType=${productType}&profileType=${profileType}`, params, { headers: headers });
    }

    updateStudentDetails(request) {
        return this.baseService.put(`${APIConstant.studentDetails}`, request);
    }

    updateEmployeeDetails(employeeProfileId, request) {
        return this.baseService.put(`${APIConstant.employeeDetails}/${employeeProfileId}`, request);
    }

    getEmployeeDetails(employeeProfileId) {
        return this.baseService.get<any>(`${APIConstant.employeeDetails}?employeeProfileId=${employeeProfileId}`);
    }

    getDefaultPersonalizedProductDesignsByProductType(productType, profileType) {
        return this.baseService.get(`${APIConstant.defaultDesignSetup}?profileType=${profileType}&productType=${productType}&includeDesignUrl=true`);
    }

    getDefaultPersonalizedProductTemplatesByProductType(productType, profileType) {
        return this.baseService.get(`${APIConstant.defaultTemplateSetup}?profileType=${profileType}&productType=${productType}&includeTemplteUrl=true`);
    }

    deleteDefaultPersonalizeProductDesign(designId) {
        return this.baseService.delete(`${APIConstant.defaultDesignSetup}?designId=${designId}`);
    }

    deleteDefaultPersonalizeProductTemplate(templateId) {
        return this.baseService.delete(`${APIConstant.defaultTemplateSetup}?templateId=${templateId}`);
    }

    addDefaultPersonalizedProductTemplate(data: any) {
        return this.baseService.post(`${APIConstant.defaultTemplateSetup}`, data);
    }

    addDefaultPersonalizedProductDesign(data: any) {
        return this.baseService.post(`${APIConstant.defaultDesignSetup}`, data);
    }

    updateDefaultPersonalizedProductTemplate(data: any) {
        return this.baseService.put(`${APIConstant.defaultTemplateSetup}`, data);
    }

    updateDefaultPersonalizedProductDesign(data: any) {
        return this.baseService.put(`${APIConstant.defaultDesignSetup}`, data);
    }

    getTemplates(productType: any, profileType: any) {
        return this.baseService.get(`${APIConstant.defaultTemplateSetup}?profileType=${profileType}&productType=${productType}`);
    }
}
