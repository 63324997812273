import { Component, OnInit } from '@angular/core';
import { RoleService, UserAuthService } from 'projects/common/src/public-api';
import { Location } from '@angular/common';
import { PersonalizedProductsService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: UserAuthService,
    private roleService: RoleService,
    private productService: PersonalizedProductsService) {
  }

  ngOnInit() {
    // this.getToken();
    this.getSecurityGroups();
  }

  private getToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const orgCode = urlParams.get('code');
    if (token) {
      this.authService.saveToken(token)
    }
    if (orgCode) {
      this.authService.saveOrgCode(orgCode)
    }
    const employeName = urlParams.get('employeeName');
		if (employeName) {
			this.authService.saveEmployeeName(employeName)
		}
    const schoolName = urlParams.get('schoolName');
		if (schoolName) {
			this.authService.saveSchoolName(schoolName)
		}

    this.getSecurityGroups();
  }

  private getSecurityGroups() {
    this.roleService.getSecurityGroups('PersonalizedProducts');
  }
}
